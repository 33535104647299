<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import {
  linewithDataChart,
  dashedLineChart,
  splineAreaChart,
  columnChart,
  columnDatalabelChart,
  barChart,
  mixedChart,
  radialChart,
  pieChart,
  donutChart
} from "./data-apex";

/**
 * Apex-chart component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      linewithDataChart: linewithDataChart,
      dashedLineChart: dashedLineChart,
      splineAreaChart: splineAreaChart,
      columnChart: columnChart,
      columnDatalabelChart: columnDatalabelChart,
      barChart: barChart,
      mixedChart: mixedChart,
      radialChart: radialChart,
      pieChart: pieChart,
      donutChart: donutChart,
      title: "Stats. sur les incidents",
      items: [
        {
          text: "Statistiques",
          href: "/"
        },
        {
          text: "Incidents",
          active: true
        }
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <!-- end row -->

    <!-- end row -->

    <div class="row">
        <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Stats. Incidents</h4>
            <!-- Column Charts -->
            <apexchart
              class="apex-charts"
              height="350"
              type="bar"
              dir="ltr"
              :series="columnChart.series"
              :options="columnChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Stats. Incidents</h4>
            <!-- Column with Data Labels -->
            <apexchart
              class="apex-charts"
              height="350"
              type="bar"
              dir="ltr"
              :series="columnDatalabelChart.series"
              :options="columnDatalabelChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

   
  </Layout>
</template>